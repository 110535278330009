import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import UserStatisticsReport from "$pages/reports-react/user-statistics-report/user-statistics-report";

class UserStatisticsReportReactWrapper extends AureliaReactReportWrapper {
  public constructor () {
    super(UserStatisticsReport)
  }
}

export default UserStatisticsReportReactWrapper

import React, {FC, HTMLAttributes} from 'react'
import {
  UserStatisticsReportDocument,
  UserStatisticsReportDownloadDocument,
  UserStatisticsReportDownloadQuery,
  UserStatisticsReportQuery,
  Reports,
  SortDirection,
  UserStatisticsReportQueryVariables,
} from "$typings/graphql-codegen";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

enum ColumnKey {
  Buid = 'buid',
  Total = 'total',
  Internal = 'internal',
  External = 'external',
  Active = 'active',
  PendingDeletion = 'pendingDeletion',
  Deleted = 'deleted',
  Prolonged = 'prolonged',
  NeverLoggedIn = 'neverLoggedIn',
  HighSecurityLevel = 'highSecurityLevel',
  MediumSecurityLevel = 'mediumSecurityLevel',
  LowSecurityLevel = 'lowSecurityLevel',
  NoneSecurityLevel = 'noneSecurityLevel',
  UserManagerAccess = 'userManagerAccess',
}

type ReportResult = UserStatisticsReportQuery
type ReportVariables = UserStatisticsReportQueryVariables
type ReportData = UserStatisticsReportQuery["reports"]["userStatistics"]["data"][0] 
type ReportDownloadResult = UserStatisticsReportDownloadQuery

const UserStatisticsReport: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_UserStatistics')
  const Filename: string = 'user-statistics-report'
  
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.Buid,
    sortDirection: SortDirection.Asc
  }
  
  const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_BUID'),
      (row: ReportData) => row[ColumnKey.Buid] ?? '',
      ColumnKey.Buid,
      ColumnWidth.Common.Buid
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_TotalUsers'),
      (row: ReportData) => String(row[ColumnKey.Total] ?? 0),
      ColumnKey.Total,
      ColumnWidth.Generic.Shorter
      
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_InternalUsers'),
      (row: ReportData) => String(row[ColumnKey.Internal] ?? 0),
      ColumnKey.Internal,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_ExternalUsers'),
      (row: ReportData) => String(row[ColumnKey.External] ?? 0),
      ColumnKey.External,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_ActiveUsers'),
      (row: ReportData) => String(row[ColumnKey.Active] ?? 0),
      ColumnKey.Active,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_PendingDeletion'),
      (row: ReportData) => String(row[ColumnKey.PendingDeletion] ?? 0),
      ColumnKey.PendingDeletion,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_Deleted'),
      (row: ReportData) => String(row[ColumnKey.Deleted] ?? 0),
      ColumnKey.Deleted,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_Prolonged'),
      (row: ReportData) => String(row[ColumnKey.Prolonged] ?? 0),
      ColumnKey.Prolonged,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_NeverLoggedIn'),
      (row: ReportData) => String(row[ColumnKey.NeverLoggedIn] ?? 0),
      ColumnKey.NeverLoggedIn,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_SecurityLevel_High'),
      (row: ReportData) => String(row[ColumnKey.HighSecurityLevel] ?? 0),
      ColumnKey.HighSecurityLevel,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_SecurityLevel_Medium'),
      (row: ReportData) => String(row[ColumnKey.MediumSecurityLevel] ?? 0),
      ColumnKey.MediumSecurityLevel,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_SecurityLevel_Low'),
      (row: ReportData) => String(row[ColumnKey.LowSecurityLevel] ?? 0),
      ColumnKey.LowSecurityLevel,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_SecurityLevel_None'),
      (row: ReportData) => String(row[ColumnKey.NoneSecurityLevel] ?? 0),
      ColumnKey.NoneSecurityLevel,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_UserStatistics_Table_Header_ManageUsers'),
      (row: ReportData) => String(row[ColumnKey.UserManagerAccess] ?? 0),
      ColumnKey.UserManagerAccess,
      ColumnWidth.Generic.Medium
    ),
  ]
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(): JSX.Element {
    return <div>{ Title }</div>
  }
  
  function dataSelector(result: ReportResult): ReportData[] {
    return result?.reports?.userStatistics?.data as ReportData[] ?? []
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length ?? 0
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.userStatistics?.blobUrl ?? ''
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.UserStatistics}
    mainQueryDocument={UserStatisticsReportDocument}
    downloadQueryDocument={UserStatisticsReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename} />
}

export default UserStatisticsReport
